import React from 'react'

function NotFound() {
  return (
    <div className="font-semibold text-center text-[larger] mt-[60px]">
      <h2>Page Not Found</h2>
      <p>Sorry, the page you are looking for doesn't exist.</p>
    </div>
  )
}

export default NotFound
