import React from 'react'
import { Progress } from 'antd'
import { formattedNumber } from '../../helpers/helperFunctions'
import { ISentimentCardProps } from './sentiment-card-props.interface'

const SentimentCard = ({ overviewData }: ISentimentCardProps) => {
  const { title, count_reviews, normalized_score_period, percent_value } =
    overviewData

  return (
    <div className="items-center relative p-6 border rounded-lg shadow-lg bg-white">
      <div className="flex justify-between">
        {title && <div className="inline-block mr-4 font-bold">{title}</div>}
        <div className="inline-block ml-8">
          {count_reviews && (
            <div className="flex flex-col items-center justify-center w-[80px] h-14 bg-cons-blue-step-down/15 rounded-lg">
              <p className="text-l font-bold text-cons-green-step-up">
                {formattedNumber(count_reviews)}
              </p>
              <p className="text-cons-gray text-xs">Reviews</p>
            </div>
          )}
        </div>
      </div>
      {percent_value && (
        <div className="text-cons-green-step-up block">
          <div className="flex items-end">
            <div className="inline-block">
              <span className="text-4xl font-bold">{percent_value}&nbsp;</span>
              /100
            </div>
          </div>
          <Progress
            percent={percent_value}
            showInfo={false}
            percentPosition={{ align: 'end', type: 'inner' }}
            size={['default', 6]}
            strokeColor="#6B9CAC"
          />
          {normalized_score_period && (
            <div className="text-xs text-cons-almostBlack mt-4">
              * Current <b>{normalized_score_period}</b> normalized score
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SentimentCard
