import React from 'react'
import { TableProps } from 'antd'
import { ICategoryData } from './category.interface'
import { simpleFormattedNumber } from '../../helpers/helperFunctions'
import { SortingParameter } from './CategoryList'
import { Link } from 'react-router-dom'

export function getCategoryListConfig(
  categorySearchValue: string,
  sortingParameters: SortingParameter
): TableProps<ICategoryData>['columns'] {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 40,
      key: 'name',
      ellipsis: true,
      className: 'font-semibold text-[#003C5B]',
      sorter: true,
      sortOrder:
        sortingParameters.columnKey === 'name' ? sortingParameters.order : null,
      onHeaderCell: () => {
        return {
          style: {
            color: '#003C5B'
          }
        }
      }
    },
    {
      title: 'Item Count',
      width: 20,
      dataIndex: 'item_count',
      key: 'item-count',
      ellipsis: true,
      className: 'font-medium text-[#003C5B]',
      render: (value) => (value ? simpleFormattedNumber(value) : ''),
      sorter: true,
      sortOrder:
        sortingParameters.columnKey === 'item-count'
          ? sortingParameters.order
          : null,
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Consumerly Index',
      width: '25px',
      align: 'center',
      key: 'consumerly-index',
      dataIndex: 'consumerly_index',
      className: 'font-bold text-[#422684]',
      sorter: true,
      sortOrder:
        sortingParameters.columnKey === 'consumerly-index'
          ? sortingParameters.order
          : null,
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Number of reviews',
      width: '25px',
      align: 'center',
      key: 'average-reviews',
      dataIndex: 'average_reviews',
      className: 'font-medium text-[#003C5B]',
      render: (value) => (value ? simpleFormattedNumber(value) : ''),
      sorter: true,
      sortOrder:
        sortingParameters.columnKey === 'average-reviews'
          ? sortingParameters.order
          : null,
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: '',
      width: '20px',
      dataIndex: '',
      key: 'action',
      render: (data) => (
        <Link
          to={`/category-details/${data.key}`}
          className="text-blue-500 underline"
        >
          See Insights
        </Link>
      )
    }
  ]
}
