import React, { useCallback, useEffect, useState } from 'react'
import { db } from '../../firebase/firebase'
import {
  collection,
  query,
  limit,
  startAfter,
  getDocs,
  orderBy,
  where,
  Query,
  DocumentData,
  getCountFromServer
} from 'firebase/firestore'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import { TablePaginationConfig, Tooltip } from 'antd'
import { getCategoryListConfig } from './category-list-config'
import { formattedNumber } from '../../helpers/helperFunctions'
import { FilterValue } from 'antd/es/table/interface'
// import { debounce } from 'lodash'
import DataTable from '../../components/Table/DataTable'
import { ICategoryData } from './category.interface'
export interface SortingParameter {
  columnKey: string
  order: 'ascend' | 'descend' | null
}

const defaultSortingParams: SortingParameter = {
  columnKey: '__name__',
  order: null
}

const getSortField = (type?: string) => {
  switch (type) {
    case 'name':
      return 'name'
    case 'item-count':
      return 'item_count'
    case 'consumerly-index':
      return 'consumerly_index'
    case 'average-reviews':
      return 'average_reviews'
    default:
      return '__name__'
  }
}

const CategoryList = () => {
  const { userData } = useUser()
  const pageSize: number = 10

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [categoriesData, setCategoriesData] = useState<ICategoryData[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalCategories, setTotalCategories] = useState(0)
  const [totalFilteredCategories, setTotalFilteredCategories] = useState(0)
  const [sortingParameters, setSortingParameters] =
    useState<SortingParameter>(defaultSortingParams)
  // const [searchTerm, setSearchTerm] = useState('')

  // const [categorySearchValue, setCategorySearchValue] = useState('')

  // const onSearch = (value: string) => {
  //   setCategorySearchValue(value)
  //   setCurrentPage(1)
  // }

  // const debounceSearch = useCallback(
  //   debounce((value: string) => {
  //     if (value.length >= 3 || value.length === 0) {
  //       onSearch(value)
  //     }
  //   }, 1000),
  //   []
  // )

  // const handleSearch = (e: any) => {
  //   const value = e?.target?.value
  //   setSearchTerm(value)
  //   debounceSearch(value)
  // }

  useEffect(() => {
    // Load the first page initially
    userData?.category_ids &&
      fetchCategory(
        userData.category_ids,
        userData?.brand_id
        // categorySearchValue
      )
  }, [currentPage, sortingParameters, userData])

  const fetchCategory = useCallback(
    async (
      categoryIds: string[],
      brandId?: string,
      searchValue?: string
    ): Promise<void> => {
      setIsLoading(true)
      try {
        if (brandId) {
          const categoriesRef = collection(db, 'category')
          const offset = (currentPage - 1) * pageSize

          const sortOrderValue: 'asc' | 'desc' | undefined =
            sortingParameters?.order === 'ascend'
              ? 'asc'
              : sortingParameters?.order === 'descend'
                ? 'desc'
                : undefined
          let q: Query<DocumentData> = categoriesRef
          let filteredQuery: Query<DocumentData> = categoriesRef
          let allCategoriesQuery: Query<DocumentData> = categoriesRef

          // Filter by categoryIds
          if (categoryIds.length > 0) {
            // Break into chunks of 10 because Firestore's 'in' operator supports up to 10 elements
            const chunks = []
            for (let i = 0; i < categoryIds.length; i += 10) {
              chunks.push(categoryIds.slice(i, i + 10))
            }

            // Combine queries for all chunks
            const queries = chunks.map((chunk) =>
              query(q, where('__name__', 'in', chunk))
            )

            // Execute all queries and merge the results
            const querySnapshots = await Promise.all(queries.map(getDocs))
            const allDocs = querySnapshots.flatMap((snapshot) => snapshot.docs)

            q = query(
              categoriesRef,
              where(
                '__name__',
                'in',
                allDocs.map((doc) => doc.id)
              )
            )
            filteredQuery = q
            allCategoriesQuery = q
          }

          // Apply search filter
          if (searchValue) {
            q = query(
              q,
              where('name', '>=', searchValue), // Нижня межа пошуку
              where('name', '<=', searchValue + '\uf8ff')
            )
            filteredQuery = q
          }

          if (sortOrderValue) {
            q = query(
              q,
              orderBy(
                getSortField(sortingParameters?.columnKey),
                sortOrderValue
              )
            )
            filteredQuery = q
          }

          // Apply pagination
          if (offset > 0) {
            const lastDocSnapshot = await getLastDocForPage(
              filteredQuery,
              offset
            )
            if (lastDocSnapshot) {
              q = query(q, startAfter(lastDocSnapshot), limit(pageSize))
            }
          } else {
            q = query(q, limit(pageSize))
          }

          // Fetch data
          const querySnapshot = await getDocs(q)

          // Fetch total count
          const filteredQuerySnapshot = await getCountFromServer(filteredQuery)

          setTotalFilteredCategories(filteredQuerySnapshot.data().count)

          if (!totalCategories) {
            const totalSnapshot = await getCountFromServer(allCategoriesQuery)
            setTotalCategories(totalSnapshot.data().count)
          }

          // Map data
          const fetchedCategories = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            key: doc.id // `key` is required for Ant Design Table
          }))
          // @ts-ignore
          setCategoriesData(fetchedCategories)
        }
      } catch (error) {
        console.error('Error fetching categories:', error)
      } finally {
        setIsLoading(false)
      }
    },
    [currentPage, sortingParameters]
  )

  // Helper function to find the last document of the previous page
  const getLastDocForPage = async (categoriesRef: any, offset: number) => {
    const q = query(categoriesRef, limit(offset))
    const snapshot = await getDocs(q)
    return snapshot.docs[snapshot.docs.length - 1]
  }

  const rowSelection = {
    columnWidth: '10px',
    selectedRowKeys,
    onChange: (newSelectedRowKeys: string[]) =>
      setSelectedRowKeys(newSelectedRowKeys)
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    if (sorter.order) {
      sorter.order !== sortingParameters.order && setCurrentPage(1)
    }
    setSortingParameters({
      columnKey: sorter.order ? sorter.columnKey : '__name__',
      order: sorter.order
    })
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mt-8 mb-10 overflow-hidden">
      <div className="flex justify-between">
        <div className="">
          <div className="">
            <div className="my-2 text-xl font-semibold">Category Insights</div>
          </div>
          <Tooltip title="Enter Category Name">
            {/*<Input*/}
            {/*  className="bg-transparent text-blue font-medium placeholder-gray-400 focus:outline-none w-60 h-10"*/}
            {/*  placeholder="Search by name"*/}
            {/*  prefix={<SearchOutlined className="text-cons-blue mr-1" />}*/}
            {/*  value={searchTerm}*/}
            {/*  onChange={handleSearch}*/}
            {/*  allowClear*/}
            {/*/>*/}
          </Tooltip>
        </div>
        <div className="mt-4 text-right text-cons-blue">
          Showing {formattedNumber(totalCategories)} categories
        </div>
      </div>
      <div className="mt-4 shadow-[1px_2px_5px_1px_rgba(29,42,68,0.12)]">
        <DataTable
          loading={isLoading || !userData}
          // @ts-ignore
          rowSelection={rowSelection}
          columns={getCategoryListConfig('', sortingParameters)}
          dataSource={categoriesData}
          rowKey="key"
          // rootClassName="[&_th]:text-cons-blue"
          rowClassName="[&_th]:text-cons-blue border border-solid border-cons-gray-step-down rounded-lg"
          locale={{
            emptyText: 'Data is being prepared. Please try again later.'
          }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            showSizeChanger: false,
            total: totalFilteredCategories,
            onChange: (page: number) => setCurrentPage(page)
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  )
}

export default CategoryList
