import React from 'react'
import { Table } from 'antd'
import { extractAntProps } from '../../helpers/antDHelpers'
import { IDataTableProps } from './data-table-props.interface'

const DataTable: React.FC<IDataTableProps> = ({
  ...props
}: IDataTableProps) => {
  return <Table {...extractAntProps(antProps, props)} />
}

export default DataTable

const antProps: string[] = [
  'tableLayout',
  'bordered',
  'columns',
  'components',
  'dataSource',
  'expandable',
  'footer',
  'loading',
  'locale',
  'rowKey',
  'rowSelection',
  'scroll',
  'showHeader',
  'size',
  'summary',
  'title',
  'onChange',
  'onHeaderRow',
  'onRow',
  'getPopupContainer',
  'sortDirections',
  'showSorterTooltip',
  'isActions',
  'defaultExpandAllRows',
  'pagination'
]
