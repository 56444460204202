import React, { useEffect, useState } from 'react'
import { Modal, Spin } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ReactComponent as LikeIcon } from '../../../../icons/like_icon.svg'
import { ReactComponent as DislikeIcon } from '../../../../icons/dislike_icon.svg'
import { ReactComponent as PricingIcon } from '../../../../icons/pricing_icon.svg'
import { ReactComponent as ValuesIcon } from '../../../../icons/values_icon.svg'
import { ReactComponent as PersonasIcon } from '../../../../icons/personas_icon.svg'
import { IConsumerProfileData } from '../../product.interface'
import parse from 'html-react-parser'
import '../analysis/Analysis.scss'

interface IDataBlockWithSortOrder extends IConsumerProfileData {
  sort_order?: number
}

const ConsumerProfile = ({
  consumerProfileData,
  isLoadingData
}: {
  consumerProfileData?: IConsumerProfileData
  isLoadingData?: boolean
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [modalType, setModalType] = useState('')
  const [sortedConsumerProfileData, setSortedConsumerProfileData] = useState<
    IConsumerProfileData[]
  >([])

  const handleReadMore = (content: string, type: string) => {
    setModalContent(content)
    setModalType(type)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (consumerProfileData) {
      const dataArray: IDataBlockWithSortOrder[] = Object.values(
        consumerProfileData
      ) as IDataBlockWithSortOrder[]

      const itemsOrder = ['likes', 'dislikes', 'personas', 'pricing', 'values']

      const sortedItems = dataArray
        .filter(Boolean) // Filter out null or undefined items
        .sort((a, b) => {
          const orderA = itemsOrder.indexOf(a.type)
          const orderB = itemsOrder.indexOf(b.type)

          // Sort by predefined order first
          if (orderA !== orderB) return orderA - orderB

          // Fallback to dynamic `sort_order` property if provided
          return (a.sort_order || 0) - (b.sort_order || 0)
        })

      setSortedConsumerProfileData(sortedItems)
    }
  }, [consumerProfileData])

  const getIcon = (type: string) => {
    switch (type) {
      case 'likes':
        return <LikeIcon />
      case 'dislikes':
        return <DislikeIcon />
      case 'pricing':
        return <PricingIcon />
      case 'values':
        return <ValuesIcon />
      case 'personas':
        return <PersonasIcon />
      default:
        return ''
    }
  }

  const isArrayEmptyOrHasOnlyNulls = (arr: IConsumerProfileData[]) =>
    !arr || arr.length === 0 || arr.every((item) => !item)

  if (isArrayEmptyOrHasOnlyNulls(sortedConsumerProfileData)) {
    return (
      <div className="mt-8 text-center text-gray-500">
        Unfortunately, we do not have customer feedback from reviews on this
        topic right now.
      </div>
    )
  }

  const renderBlock = (block: IDataBlockWithSortOrder, index: number) => (
    <div
      key={index}
      className="relative p-6 border rounded-lg shadow-lg bg-white"
    >
      <div className="flex justify-between items-start">
        <div className="text-blue-600 text-2xl mb-2">
          {getIcon(block.type || '')}
        </div>
        <InfoCircleOutlined className="text-gray-400 text-xl" />
      </div>
      <h3 className="text-xl font-bold mb-2 text-cons-blue">{block.title}</h3>
      <p className="text-gray-600 line-clamp-2">{parse(block.full_text)}</p>
      <button
        onClick={() => handleReadMore(block.full_text, block.title)}
        className="text-cons-blue font-medium hover:underline mt-2"
      >
        Read More
      </button>
    </div>
  )

  return (
    <div className="mt-8">
      {isLoadingData && (
        <div className="flex w-full justify-center items-center h-40">
          <Spin size="large" />
        </div>
      )}

      {/* Render blocks if data is available */}
      {sortedConsumerProfileData.length > 0 && (
        <>
          {/* Render the first row: Likes, Dislikes, and optionally Personas */}
          <div
            className={`grid gap-4 ${
              sortedConsumerProfileData.slice(0, 3).length === 1
                ? 'grid-cols-1'
                : sortedConsumerProfileData[2]?.type === 'personas'
                  ? 'grid-cols-1 md:grid-cols-3'
                  : 'grid-cols-1 md:grid-cols-2'
            }`}
          >
            {sortedConsumerProfileData
              .slice(0, 3)
              .map((block, index) =>
                block.type === 'personas' || index < 2
                  ? renderBlock(block, index)
                  : null
              )}
          </div>

          {/* Render the second row: Pricing and Values */}
          <div
            className={`grid gap-4 ${
              sortedConsumerProfileData.filter(
                (block) => block.type === 'pricing' || block.type === 'values'
              ).length === 1
                ? 'grid-cols-1'
                : 'grid-cols-1 md:grid-cols-2'
            } mt-4`}
          >
            {sortedConsumerProfileData
              .filter(
                (block) => block.type === 'pricing' || block.type === 'values'
              )
              .map(renderBlock)}
          </div>
        </>
      )}

      {/* Modal for displaying detailed content */}
      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <h2 className="text-cons-blue">{modalType}</h2>
        <p className="pt-6 text-box">{parse(modalContent)}</p>
      </Modal>
    </div>
  )
}

export default ConsumerProfile
