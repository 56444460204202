import React from 'react'
import { IBrandEquityProps } from './brand-equity-props.interface'
import getArrow from '../../helpers/getArrow'
import { ReactComponent as LinkArrow } from '../../icons/link_arrow.svg'
import { formatCurrency, formattedNumber } from '../../helpers/helperFunctions'
import { IStatsCardData } from '../../containers/brand/brand.interface'
import { useNavigate } from 'react-router-dom'

const BrandEquity: React.FC<IBrandEquityProps> = ({
  stats,
  isCurrencySigns,
  titles,
  isLinkCard,
  linkCard,
  isHideArrow
}: IBrandEquityProps) => {
  const navigate = useNavigate()

  const getCardStyle = (type: string) => {
    switch (type) {
      case 'positive':
        return 'bg-cons-green/10 text-cons-green'
      case 'negative':
        return 'bg-cons-red/10 text-cons-red'
      case 'blank':
        return 'bg-cons-blue-step-down/10 text-cons-purple'
      case 'neutral':
        return 'bg-cons-yellow/10 text-cons-yellow-step-up'
      default:
        return 'bg-cons-yellow/10 text-cons-yellow-step-up'
    }
  }

  const handleCardClick = (param: string) => {
    navigate(`/product-list?review_status=${param}`)
  }

  return (
    <div className="mx-auto grid gap-4 grid-cols-1 md:grid-flow-col md:auto-cols-[minmax(0,1fr)]">
      {stats &&
        stats.map((item: IStatsCardData, index) => (
          <div
            key={index}
            onClick={() =>
              isLinkCard && linkCard && handleCardClick(linkCard[index])
            }
            className={`flex justify-between items-center p-4 rounded-lg shadow-md ${getCardStyle(item.type)} ${isLinkCard && 'transition-transform duration-300 hover:scale-105 cursor-pointer shadow-[0_2px_10px_rgba(0,0,0,0.1)] hover:shadow-[0_-1px_15px_rgba(0,0,0,0.2)]'}`}
          >
            <div className="text-left items-center">
              <div className="text-lg font-bold">
                {isCurrencySigns ? (
                  <span>
                    {item.type === 'negative' ? '-' : ''}
                    {formatCurrency(item.current)}
                  </span>
                ) : (
                  formattedNumber(item.current)
                )}
              </div>
              <div className="text-sm">{item.title || titles[index]}</div>
            </div>
            <div className="text-right">
              {item.previous ? (
                <div className="flex flex-col justify-center text-right">
                  <p className="text-sm font-semibold text-cons-gray-step-up text-4">
                    {isCurrencySigns ? (
                      <span>
                        {item.type === 'negative' ? '-' : ''}
                        {formatCurrency(item.previous)}
                      </span>
                    ) : (
                      formattedNumber(item.previous)
                    )}{' '}
                    {item.arrow &&
                      !isHideArrow &&
                      getArrow(item.arrow, 'inline')}
                  </p>
                  <p className="text-xs text-cons-gray-step-up-50">
                    Previous Month
                  </p>
                </div>
              ) : (
                ''
              )}
              {isLinkCard ? (
                <div className="flex flex-col justify-center text-right">
                  <LinkArrow />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
    </div>
  )
}

export default BrandEquity
