import React from 'react'
import { AuthProvider } from './contexts/authContext'
import Navigation from './components/navigation/Navigation'
import { routesArray } from './routes/routes'
import { useRoutes } from 'react-router-dom'

function App() {
  const routesElement = useRoutes(routesArray)

  return (
    <AuthProvider>
      <div className="App">
        <div className="w-full h-screen flex flex-col">
          <Navigation />
          <div className="overflow-x-hidden">{routesElement}</div>
        </div>
      </div>
    </AuthProvider>
  )
}

export default App
