import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { IChartData } from '../../containers/brand/brand.interface'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

interface IChartProps {
  dataChart: IChartData
}

const Chart: React.FC<IChartProps> = ({ dataChart }: IChartProps) => {
  const getChartColor = (type: string) => {
    switch (type) {
      case 'positive':
        return '#38AD6A'
      case 'negative':
        return '#DE350A'
      case 'neutral':
        return '#E3CC00'
      case 'blank':
        return '#422684'
      default:
        return '#38AD6A'
    }
  }

  const data = {
    labels: dataChart.chart_bottom_labels,
    datasets: dataChart.data_sets.map((data) => {
      return {
        label: data.label,
        data: data.data,
        borderColor: getChartColor(data.type),
        backgroundColor: getChartColor(data.type),
        tension: 0.4 // for smooth curves
      }
    })
  }

  const options = {
    maintainAspectRatio: false, // Disables maintaining the aspect ratio
    responsive: true,

    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number) =>
            `${new Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)}`
        }
      },
      x: {
        ticks: {
          maxRotation: 13,
          minRotation: 3
        }
      }
    },
    plugins: {
      legend: {
        display: false // This hides the legend (buttons with labels)
      }
    }
  }

  return (
    <div style={{ height: '300px', width: '100%' }}>
      {/*@ts-ignore */}
      <Line data={data} options={options} />
    </div>
  )
}

export default Chart
