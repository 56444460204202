import React from 'react'
import Chart from '../../../../components/Chart/Chart'
import { BrandsData, ICategoryData } from '../../category.interface'
import DataTable from '../../../../components/Table/DataTable'
// import { getTopBrandsListConfig } from './topBrandsTable/top-brands-list-config'
// import TopBrandsTable from './topBrandsTable/TopBrandsTable'
import { getBrandListConfig } from './top-brands-list-config'

interface ProductDetailsProps {
  categoryData: ICategoryData
  topBrandsData?: BrandsData[]
  isLoading: boolean
  handleChangePage: (page: number) => void
  totalTopBrands: number
  currentPage: number
}

const CategoryDashboard: React.FC<ProductDetailsProps> = ({
  categoryData,
  topBrandsData,
  isLoading,
  handleChangePage,
  currentPage,
  totalTopBrands
}: ProductDetailsProps) => {
  return (
    <div>
      {categoryData?.review_trend_chart && (
        <div className="mt-6">
          <div className=" text-lg">Review Trends</div>
          <div className="text-sm mb-4 text-cons-gray">
            Category review trends for trailing 12 months with trend lines for{' '}
            <span className="text-cons-green">positive</span>,{' '}
            <span className="text-cons-yellow">neutral</span>, and{' '}
            <span className="text-cons-red">negative</span> reviews
          </div>
          <Chart dataChart={categoryData.review_trend_chart} />
        </div>
      )}
      <h2 className="mt-4 mb-2">Top Brands by Consumerly Index</h2>
      {/*<TopBrandsTable isLoading={isLoading} topBrandsData={topBrandsData} />*/}
      <DataTable
        loading={isLoading || !topBrandsData}
        // @ts-ignore
        // rowSelection={rowSelection}
        columns={getBrandListConfig()}
        dataSource={topBrandsData}
        rowKey="key"
        // rootClassName="[&_th]:text-cons-blue"
        // className="[&_th]:text-cons-blue border border-solid border-cons-gray-step-down rounded-lg"
        pagination={{
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
          total: totalTopBrands,
          onChange: (page: number) => handleChangePage(page)
        }}
        // onChange={handleTableChange}
      />
    </div>
  )
}

export default CategoryDashboard
