import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Spin } from 'antd'
import parse from 'html-react-parser'
import { IAnalysisData } from '../../product.interface'
import { ReactComponent as AnalysisIcon } from '../../../../icons/analysis.svg'
import './Analysis.scss'

const ExpandableBlock = ({ title, markdownText, key }: any) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="rounded-lg p-4 mb-4" key={key}>
      <div className="flex justify-between">
        <h2 className="font-bold text-xl mb-2 text-cons-blue">{title}</h2>
      </div>

      <div>
        <div className="relative overflow-hidden">
          <div
            className={`text-box overflow-hidden transition-all duration-300 relative ${isExpanded ? 'max-h-full' : 'max-h-60'} ${isExpanded ? '' : 'shadow-b'}`}
            style={isExpanded ? { height: 'auto' } : { maxHeight: '160px' }}
          >
            {parse(markdownText)}
          </div>
        </div>
        <button
          onClick={toggleExpand}
          className="text-cons-blue font-medium ml-8"
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      </div>
    </div>
  )
}

// Parent component for both blocks
const AnalysisTab = ({
  brandId,
  isLoadingData,
  analysisData,
  errorMessage,
  handleGetAnalysisData
}: {
  brandId: string
  isLoadingData: boolean
  errorMessage: string
  handleGetAnalysisData: (() => void) | undefined
  analysisData?: IAnalysisData
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { productId }: { productId?: string } = useParams()

  useEffect(() => {
    setLoading(isLoadingData)
  }, [isLoadingData, analysisData, errorMessage])

  // Handle button click to manually trigger fetch
  const handleClick = () => {
    setLoading(true) // Show the loader
    setTimeout(() => {
      handleGetAnalysisData && handleGetAnalysisData() // Keep displaying the same text
    }, 3000) // Timeout set to 3 seconds
  }

  useEffect(() => {
    if (analysisData) {
      const unsubscribe = handleGetAnalysisData && handleGetAnalysisData() // Subscribes on component mount

      // Cleanup function to unsubscribe on unmount
      return () => {
        if (unsubscribe) {
          // @ts-ignore
          unsubscribe()
        }
      }
    }
  }, [brandId, productId]) // Depend on brandId and productId

  const analysisTitles: string[] = [
    'New Item Ideas',
    'Recommendations to Improve the Item'
  ]

  return (
    <div className="container mx-auto p-4">
      <h2 className="my-2 mt-8 font-medium">See Analysis & Recommendations</h2>

      <div className="bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
        {loading || isLoadingData ? (
          <div className="grid min-h-[300px] place-items-center">
            <Spin size="large" />
          </div>
        ) : analysisData ? (
          <div className="space-y-4">
            {(Object.keys(analysisData) as Array<keyof IAnalysisData>).map(
              (key, index) => {
                const dataBlock = analysisData[key]

                return dataBlock ? (
                  <ExpandableBlock
                    key={key}
                    title={analysisTitles[index]}
                    markdownText={dataBlock.full_text}
                  />
                ) : null
              }
            )}
          </div>
        ) : (
          <div className="flex flex-col sm:flex-row px-8">
            <div className="p-3 w-6/12 max-sm:w-full text-center">
              <AnalysisIcon className="w-2/3 " />
            </div>
            <div className="ml-4 p-6 mb-4 w-6/12 max-sm:w-full flex items-center">
              <div>
                <h3 className="mb-4 font-semibold">
                  Would you like to start analyzing this product now?
                </h3>
                <div className="mb-4">
                  After activating this product, you will get unlimited access
                  to the Analysis & Recommendations for this month.
                </div>
                <Button
                  type="primary"
                  className="bg-cons-blue hover:bg-cons-blue-step-down h-10"
                  onClick={() => handleClick()}
                >
                  Generate analysis
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalysisTab
