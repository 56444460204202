import React from 'react'
import Login from '../containers/login/Login'
import Brand from '../containers/brand/Brand'
import ProductList from '../containers/productList/ProductList'
import NotFound from '../containers/notFound/NotFoundPage'
import ProtectedRoute from './ProtectedRoute'
import ProductDetails from '../containers/productDetails/ProductDetails'
import SentimentOverview from '../containers/sentimentOverview/SentimentOverview'
import CategoryList from '../containers/catogoryList/CategoryList'
import CategoryDetails from '../containers/categoryDetails/CategoryDetails'

export const routesArray = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/brand',
    element: (
      <ProtectedRoute>
        <Brand />
      </ProtectedRoute>
    )
  },
  {
    path: '/product-list',
    element: (
      <ProtectedRoute>
        <ProductList />
      </ProtectedRoute>
    )
  },
  {
    path: '/product-details/:productId',
    element: (
      <ProtectedRoute>
        <ProductDetails />
      </ProtectedRoute>
    )
  },
  {
    path: '/product-details/:productId/overview/:customerSentimentId',
    element: (
      <ProtectedRoute>
        <SentimentOverview />
      </ProtectedRoute>
    )
  },
  {
    path: '/category-insights',
    element: (
      <ProtectedRoute>
        <CategoryList />
      </ProtectedRoute>
    )
  },
  {
    path: '/category-details/:categoryId',
    element: (
      <ProtectedRoute>
        <CategoryDetails />
      </ProtectedRoute>
    )
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Brand />
      </ProtectedRoute>
    )
  },
  {
    path: '*',
    element: <NotFound />
  }
]
