import React from 'react'
import { ReactComponent as ArrowGreenUp } from '../icons/arrow_green_up.svg'
import { ReactComponent as ArrowRedUp } from '../icons/arrow_red_up.svg'
import { ReactComponent as ArrowRedDown } from '../icons/arrow_red_down.svg'
import { ReactComponent as ArrowGreenDown } from '../icons/arrow_green_down.svg'
import { ReactComponent as ArrowNeutral } from '../icons/arrow_neutral.svg'
const getArrow = (arrowType: string, classes: string) => {
  const getCardStyle = (type: string) => {
    switch (type) {
      case 'up_green':
        return <ArrowGreenUp className={classes} />
      case 'up_red':
        return <ArrowRedUp className={classes} />
      case 'down_green':
        return <ArrowGreenDown className={classes} />
      case 'down_red':
        return <ArrowRedDown className={classes} />
      case 'neutral':
        return <ArrowNeutral className={classes} />
      default:
        return ''
    }
  }

  return getCardStyle(arrowType)
}
export default getArrow
