import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Progress } from 'antd'
import { ReactComponent as HoverInfoIcon } from '../../icons/hover_info.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { CategoryCardProps } from './customer-sentiment-props.interface'
import { ratingStyles } from '../../constants/ratingTags'
ChartJS.register(ArcElement, Tooltip, Legend)

const CategoryCard = ({ cardData }: CategoryCardProps) => {
  const {
    title,
    rating,
    percent_value,
    collection_link
  }: CategoryCardProps['cardData'] = cardData
  const navigate = useNavigate()
  const { productId } = useParams()

  const handleCardClick = () => {
    const customerSentimentId = collection_link._key.path.segments.at(-1)
    navigate(`/product-details/${productId}/overview/${customerSentimentId}`)
  }

  return (
    <div
      onClick={handleCardClick}
      className={`bg-white text-left rounded-lg p-4 w-full max-w-sm mb-4 relative transform transition-transform duration-300 ${title == 'NPS' || title == 'Consumerly Index' ? 'pointer-events-none' : 'hover:scale-105 hover:shadow-[0_-1px_15px_rgba(0,0,0,0.2)] cursor-pointer'} shadow-[0_2px_10px_rgba(0,0,0,0.1)]`}
    >
      <div className="absolute right-8 w-4 h-3">
        <HoverInfoIcon />
      </div>
      <div className="">
        <div className="text-lg font-medium mx-auto my-0">{title}</div>
      </div>
      <div
        className={`my-0 mt-2 px-2 py-1 rounded-full text-center text-xs w-[90px] font-medium ${ratingStyles[rating]}`}
      >
        {rating.charAt(0).toUpperCase() + rating.slice(1)}
      </div>
      <div className="text-cons-green-step-up block my-4">
        <div className="">
          <span className="text-4xl font-bold">{percent_value}&nbsp;</span>
          /100
        </div>
        <Progress
          className="mt-3"
          percent={percent_value}
          showInfo={false}
          percentPosition={{ align: 'end', type: 'inner' }}
          size={['default', 6]}
          strokeColor="#6B9CAC"
        />
      </div>
    </div>
  )
}

const CategoryCards = ({ cardsData }: any) => {
  return (
    <div className="mx-auto grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
      {cardsData.map((item: any, index: number) => (
        <CategoryCard key={index} cardData={item} />
      ))}
    </div>
  )
}

export default CategoryCards
