import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext'
import { doSignOut } from '../../firebase/auth'
import { ReactComponent as UserImage } from '../../icons/profile-user.svg'
import { ReactComponent as ConsumerLogo } from '../../icons/consumerly-logo.svg'
import { useUser } from '../../contexts/UserProvider/UserProvider'

const NavigationAuth = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { userData, logout } = useUser()
  const isBrandActive = location.pathname.includes('/brand')
  const isProductsActive = location.pathname.includes('/product-list')
  const isCategory = location.pathname.includes('/category-insights')

  const [userName, setUserName] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    if (userData && userData.name) {
      setUserName(userData.name)
    }
  }, [userData])

  return (
    <header className="pb-[71px] max-sm:pb-[220px] max-md:h-[410px]">
      <nav className="z-10 fixed w-full bg-cons-blue-step-down p-4 flex justify-between items-center">
        <div className="w-full max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center text-cons-blue">
          {/* Logo Section */}
          <div className="text-left mb-4 md:mb-0">
            <ConsumerLogo className="w-32 md:w-48" />
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
            <Link
              className={`text-center py-1.5 px-4 rounded-md transition ${isBrandActive ? 'bg-cons-white' : 'bg-transparent hover:bg-cons-white/15'}`}
              to="/brand"
            >
              Brand
            </Link>
            <Link
              className={`text-center py-1.5 px-4 rounded-md transition ${isProductsActive ? 'bg-cons-white' : 'bg-transparent hover:bg-cons-white/15'}`}
              to="/product-list"
            >
              My Products
            </Link>
            <Link
              className={`text-center py-1.5 px-4 rounded-md transition ${isCategory ? 'bg-cons-white' : 'bg-transparent hover:bg-cons-white/15'}`}
              to="/category-insights"
            >
              Category Insights
            </Link>
          </div>

          {/* User Info and Dropdown */}
          <div
            className="relative flex items-center max-md:mt-2 cursor-pointer min-w-[120px]"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className="text-sm md:text-base py-1.5 px-4 rounded-md hover:bg-white/15 transition">
              {userName}
            </div>
            <UserImage className="w-8 h-8 rounded-full" />
            {isDropdownOpen && (
              <div className="dropdown-menu absolute right-0 top-8 mt-2 w-48 bg-cons-white rounded-lg shadow-lg z-10">
                <ul className="py-2">
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      doSignOut().then(() => {
                        setIsDropdownOpen(false)
                        logout()
                        navigate('/login')
                      })
                    }}
                  >
                    Log out
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  )
}

const Navigation = () => {
  return (
    <AuthContext.Consumer>
      {(authUser: any) => (authUser.currentUser ? <NavigationAuth /> : '')}
    </AuthContext.Consumer>
  )
}

export default Navigation
