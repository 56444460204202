import { Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/authContext'

function ProtectedRoute({ children }: any) {
  // @ts-ignore
  const { userLoggedIn } = useAuth()
  if (!userLoggedIn) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" replace />
  }

  // If authenticated, render the children components (e.g., BrandPage)
  return children
}

export default ProtectedRoute
