export interface AntProps {
  [key: string]: string | boolean | Function
}

export const extractAntProps: Function = (antProps: string[], props: any) => {
  const exProps: AntProps = {}

  for (let prop of antProps) {
    if (props[prop] !== undefined) {
      exProps[prop] = props[prop]
    }
  }

  return exProps
}
