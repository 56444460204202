import React from 'react'
import { IProductData } from '../../../brand/brand.interface'
import CategoryCards from '../../../../components/customerSentiment/CustomerSentiment'
import BrandEquity from '../../../../components/brandEquity/BrandEquity'
import Chart from '../../../../components/Chart/Chart'

interface ProductDetailsProps {
  productData: IProductData
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  productData
}: ProductDetailsProps) => {
  return (
    <div>
      {productData?.stats?.trends_review_chart && (
        <div className="mt-6">
          <div className=" text-lg">Review Trends</div>
          <div className="text-sm mb-4 text-cons-gray">
            Review trends for trailing 12 months with trend lines for{' '}
            <span className="text-cons-green">positive</span>,{' '}
            <span className="text-cons-yellow">neutral</span>, and{' '}
            <span className="text-cons-red">negative</span> reviews
          </div>
          <Chart dataChart={productData.stats.trends_review_chart} />
        </div>
      )}
      {productData?.stats?.quality_review && (
        <div className="mt-6">
          <div className="mb-2 text-lg">Review Quality</div>
          <BrandEquity
            stats={productData?.stats?.quality_review}
            titles={['Total Reviews', 'Verified Reviews', 'Net Reviews']}
          />
        </div>
      )}
      {productData?.stats?.sentiment_review && (
        <div className="mt-6">
          <div className="mb-2 text-lg">Review Sentiment</div>
          <BrandEquity
            stats={productData?.stats.sentiment_review}
            titles={[
              'Total Positive Reviews',
              'Total Neutral Reviews',
              'Total Negative Reviews'
            ]}
          />
        </div>
      )}
      {productData?.stats?.customer_sentiment && (
        <div className="mt-6">
          <div className="mb-2 text-lg">Customer Sentiment</div>
          <CategoryCards cardsData={productData.stats.customer_sentiment} />
        </div>
      )}
    </div>
  )
}

export default ProductDetails
