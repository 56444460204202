import React from 'react'
import { IMainDataCardProps } from './main-category-data-props.interface'
import { Tooltip } from 'antd'
import { ReactComponent as InfoIcon } from '../../icons/info.svg'
import { simpleFormattedNumber } from '../../helpers/helperFunctions'

const MainCategoryCard: React.FC<IMainDataCardProps> = ({
  cardData
}: IMainDataCardProps) => {
  return (
    <div className="bg-cons-blue-step-down/15 shadow-md flex flex-col mt-6 border-2 border-solid border-cons-blue/50 rounded-[20px]">
      <h3 className="p-4 px-8 md:text-2xl font-medium text-gray-800 ">
        {cardData.name}
      </h3>
      <div className="border-b-cons-blue/25 border-b border-solid w-full border-[1.5px]" />
      {/* Stats Container */}
      <div className="p-4 px-8 justify-between items-center text-center flex flex-col md:flex-row">
        {/* Number of Brands */}
        <div className="flex flex-col max-sm:flex-col text-left max-md:text-center">
          <span className="text-3xl font-extrabold text-cons-purple">
            {simpleFormattedNumber(cardData.brands_count)}
          </span>
          <span className="text-sm text-cons-almostBlack">
            Number of brands
          </span>
        </div>

        {/* Number of Items */}
        <div className="flex flex-col text-left max-md:text-center">
          <span className="text-3xl font-extrabold text-cons-purple">
            {simpleFormattedNumber(cardData.item_count)}
          </span>
          <span className="text-sm text-cons-almostBlack">Number of items</span>
        </div>

        {/* Avg. Consumerly Index */}
        <div className="flex flex-col text-left max-md:text-center">
          <span className="text-3xl font-extrabold text-cons-purple">
            {simpleFormattedNumber(cardData.consumerly_index)}
          </span>
          <span className="flex text-sm md:text-base text-cons-almostBlack">
            Avg. Consumerly Index&nbsp;
            <span className="ml-1 text-xs text-gray-400 cursor-pointer pt-1">
              <Tooltip title="This number describes the overall customer satisfaction of a category. It is based on the sentiment found within the reviews instead of the traditional star rating.">
                <InfoIcon />
              </Tooltip>
            </span>
          </span>
        </div>
        {/* Average # of Reviews */}
        <div className="flex flex-col text-left max-md:text-center">
          <span className="text-3xl font-extrabold text-cons-purple">
            {simpleFormattedNumber(cardData.average_reviews)}
          </span>
          <span className="text-sm md:text-base text-cons-almostBlack">
            Average # of reviews
          </span>
        </div>
      </div>
    </div>
  )
}
export default MainCategoryCard
