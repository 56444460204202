import React, { useCallback, useEffect, useState } from 'react'
import {
  collection,
  query,
  limit,
  startAfter,
  getDocs,
  orderBy,
  Query,
  DocumentData,
  getCountFromServer,
  getFirestore,
  doc,
  getDoc
} from 'firebase/firestore'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import { Radio, Spin } from 'antd'

import { useParams } from 'react-router-dom'
import BackButton from '../../components/backButton/BackButton'
import MainCategoryCard from '../../components/mainCategoryData/MainCategoryCard'
import Icons from '../../icons/SVGComponents/icons'
import CategoryDashboard from './categoryDetailsTabs/dashboard/CategoryDashboard'
import { BrandsData, ICategoryData, ProductIdeas } from './category.interface'
import NewProductIdeas from './categoryDetailsTabs/newProductIdeas/NewProductIdeas'
export interface SortingParameter {
  columnKey: string
  order: 'ascend' | 'descend' | null
}

type dataTabs = 'dashboard' | 'new_ideas'

const CategoryDetails = () => {
  const { userData } = useUser()
  const { NewIdeasIcon, DashboardIcon } = Icons
  const pageSize = 10
  const { categoryId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [categoryData, setCategoryData] = useState<ICategoryData>()
  const [activeTab, setActiveTab] = useState<dataTabs>('dashboard')
  const [productIdeasData, setProductIdeasData] = useState<ProductIdeas>()
  const [topBrandsData, setTopBrandsData] = useState<BrandsData[]>()
  const [totalTopBrands, setTotalTopBrands] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    userData?.brand_id && getCategoryData(categoryId)
  }, [categoryId, userData])

  const getCategoryData: Function = useCallback(
    async (categoryId: string): Promise<void> => {
      setIsLoading(true)
      try {
        const db = getFirestore()
        const categoryDocRef = doc(db, 'category', categoryId)
        const productDoc = await getDoc(categoryDocRef)

        if (productDoc.exists()) {
          //@ts-ignore
          setCategoryData(productDoc.data())
        } else {
          console.error('Product not found')
        }
        await getTopBrandsData(pageSize, 1)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    },
    [categoryId]
  )

  const getLastDocForPage = async (
    queryRef: Query<DocumentData>,
    offset: number
  ): Promise<DocumentData | null> => {
    const q = query(queryRef, limit(offset))
    const snapshot = await getDocs(q)
    return snapshot.docs[offset - 1] || null // Return the document at the offset
  }

  const getTopBrandsData: Function = useCallback(
    async (
      pageSize: number, // Number of items per page
      currentPage: number // Current page number
    ): Promise<void> => {
      try {
        if (categoryId) {
          const db = getFirestore()
          const topBrandsRef = collection(
            db,
            'category',
            categoryId,
            'topBrands'
          )

          // Calculate offset
          const offset = (currentPage - 1) * pageSize

          // Base query with ordering
          let q: Query<DocumentData> = query(
            topBrandsRef,
            orderBy('consumerly_index', 'desc') // Sort by rating in descending order
          )

          // Handle pagination
          if (offset > 0) {
            const lastDocSnapshot = await getLastDocForPage(q, offset)
            if (lastDocSnapshot) {
              q = query(q, startAfter(lastDocSnapshot), limit(pageSize))
            }
          } else {
            q = query(q, limit(pageSize))
          }

          // Fetch data
          const snapshot = await getDocs(q)

          // Map data to an array
          const topBrands = snapshot.docs.map((doc) => ({
            id: doc.id, // Include document ID
            ...doc.data() // Include document fields
          }))
          //@ts-ignore
          setTopBrandsData(topBrands) // Update state with fetched data

          // Optionally, calculate the total count of documents
          const countSnapshot = await getCountFromServer(topBrandsRef)
          setTotalTopBrands(countSnapshot.data().count)
        }
      } catch (e) {
        console.error('Error fetching top brands:', e)
      } finally {
        setIsLoading(false)
      }
    },
    [categoryId]
  )

  const getNewProductIdeas: Function = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    try {
      if (categoryId) {
        const db = getFirestore()
        const ideasDocRef = collection(
          db,
          'category',
          categoryId,
          'productIdeas'
        )

        const ideasDoc = await getDocs(ideasDocRef)
        if (!ideasDoc.empty) {
          //@ts-ignore
          setProductIdeasData(ideasDoc.docs[0].data())
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [userData])

  const handleTabChange = (key: dataTabs) => {
    setActiveTab(key)
    if (key === 'new_ideas' && !productIdeasData) {
      getNewProductIdeas()
    }
  }

  const tabPanel = () => {
    return (
      <Radio.Group
        defaultValue="dashboard"
        className="flex justify-center max-sm:flex-col [&_.ant-radio-button-wrapper]:h-12 [&_.ant-radio-button-wrapper]:flex [&_.ant-radio-button-wrapper]:items-center [&_.ant-radio-button-wrapper]:justify-center [&_.ant-radio-button-wrapper]:px-5  [&_.ant-radio-button-wrapper]:bg-cons-blue/5 [&_.ant-radio-button-wrapper-checked]:bg-cons-blue [&_.ant-radio-button-wrapper-checked]:text-white ![&_.ant-radio-button-wrapper-checked]:bg-green-700"
        optionType="button"
        buttonStyle="solid"
        onChange={(event) => handleTabChange(event.target.value)}
      >
        <Radio.Button value="dashboard">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'dashboard' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              <DashboardIcon
                width="22px"
                height="22px"
                stroke={`${activeTab === 'dashboard' ? '#fff' : '#2F5497'}`}
              />
            </i>
            Dashboard
          </div>
        </Radio.Button>
        <Radio.Button value="new_ideas">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'new_ideas' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              <NewIdeasIcon
                width="22px"
                height="22px"
                stroke={`${activeTab === 'new_ideas' ? '#fff' : '#2F5497'}`}
              />
            </i>
            New Product Ideas
          </div>
        </Radio.Button>
      </Radio.Group>
    )
  }

  const getTabContent = (tab: dataTabs, data: ICategoryData) => {
    switch (tab) {
      case 'dashboard':
        return (
          <CategoryDashboard
            categoryData={data}
            topBrandsData={topBrandsData}
            isLoading={isLoading}
            handleChangePage={(page: number) => {
              getTopBrandsData(pageSize, page)
              setCurrentPage(page)
            }}
            totalTopBrands={totalTopBrands}
            currentPage={currentPage}
          />
        )
      case 'new_ideas':
        return (
          <NewProductIdeas
            isLoadingData={isLoading}
            productIdeasData={productIdeasData}
          />
        )
      default:
        return ''
    }
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mb-12 relative">
      {!isLoading && (
        <div>
          <BackButton />
          <div className="my-8 pt-1.5 text-xl">Category Insights</div>
        </div>
      )}
      {categoryData ? (
        <div>
          <MainCategoryCard cardData={categoryData} />
          <div>
            <div className="mt-6">{tabPanel()}</div>
            {categoryData && getTabContent(activeTab, categoryData)}
          </div>
        </div>
      ) : isLoading ? (
        <Spin className="w-full flex justify-center pt-[50px]" />
      ) : (
        <div className="font-semibold text-center text-[larger] mt-[60px]">
          Product Not Fount
        </div>
      )}
    </div>
  )
}

export default CategoryDetails
