const simpleFormattedNumber = (value: number) => {
  return new Intl.NumberFormat('en-US').format(value)
}

const formattedNumber = (value: number) => {
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(2)}M`
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(0)}K`
  } else {
    return new Intl.NumberFormat('en-US').format(value)
  }
}

const formatCurrency = (value: number): string => {
  if (value >= 1_000_000) {
    // Manually format millions with two decimal places
    return `$${(value / 1_000_000).toFixed(2)}M`
  } else {
    // Use Intl.NumberFormat for thousands and smaller
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact'
    }).format(value)
  }
}

export { formattedNumber, formatCurrency, simpleFormattedNumber }
