import React, { useState } from 'react'
// import { IProductData } from '../../../brand/brand.interface'
// import CategoryCards from '../../../../components/customerSentiment/CustomerSentiment'
// import BrandEquity from '../../../../components/brandEquity/BrandEquity'
// import Chart from '../../../../components/Chart/Chart'
import { ProductIdeas } from '../../category.interface'
// import DataTable from '../../../../components/Table/DataTable'
import { Spin } from 'antd'
import parse from 'html-react-parser'

interface ProductDetailsProps {
  isLoadingData: boolean
  productIdeasData?: ProductIdeas
}

const ExpandableBlock = ({ title, markdownText, key }: any) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="rounded-lg p-4 mb-4" key={key}>
      <div className="flex justify-between">
        <h2 className="font-bold text-xl mb-2 text-cons-blue">{title}</h2>
      </div>

      <div>
        <div className="relative overflow-hidden">
          <div
            className={`text-box overflow-hidden transition-all duration-300 relative ${isExpanded ? 'max-h-full' : 'max-h-60'} ${isExpanded ? '' : 'shadow-b'}`}
            style={isExpanded ? { height: 'auto' } : { maxHeight: '160px' }}
          >
            {parse(markdownText)}
          </div>
        </div>
        <button
          onClick={toggleExpand}
          className="text-cons-blue font-medium ml-8"
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      </div>
    </div>
  )
}

const NewProductIdeas: React.FC<ProductDetailsProps> = ({
  isLoadingData,
  productIdeasData
}: ProductDetailsProps) => {
  return (
    <div className="container mx-auto p-4">
      <div className="bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
        {isLoadingData && (
          <div className="flex w-full justify-center text-center items-center h-40">
            <Spin size="large" />
          </div>
        )}
        {productIdeasData ? (
          <>
            {productIdeasData?.recommendation?.full_text && !isLoadingData && (
              <ExpandableBlock
                title={productIdeasData?.recommendation?.title}
                markdownText={productIdeasData?.recommendation?.full_text}
              />
            )}

            {productIdeasData?.desirable_product_attributes?.full_text &&
              !isLoadingData && (
                <ExpandableBlock
                  title={productIdeasData?.desirable_product_attributes?.title}
                  markdownText={
                    productIdeasData?.desirable_product_attributes?.full_text
                  }
                />
              )}
          </>
        ) : (
          <div className="font-semibold text-center text-[larger] mt-[60px]">
            No product ideas available at the moment.
          </div>
        )}
      </div>
    </div>
  )
}

export default NewProductIdeas
