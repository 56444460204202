import React from 'react'
import { Checkbox } from 'antd'

interface ICheckboxGroup {
  options: { label: string; value: string }[]
  selectedOptions: string[]
  // eslint-disable-next-line no-unused-vars
  onChange: (e: string[]) => void
  disabled: boolean
}

const CheckboxGroup = ({ selectedOptions, ...res }: ICheckboxGroup) => (
  <Checkbox.Group
    options={res.options}
    value={selectedOptions}
    onChange={res.onChange}
    className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 items-center"
    disabled={res.disabled}
  />
)

export default CheckboxGroup
