import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../../firebase/firebase'
import { onAuthStateChanged } from 'firebase/auth'

export const AuthContext = React.createContext(null)

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [isEmailUser, setIsEmailUser] = useState(false)
  const [loading, setLoading] = useState(true)

  async function initializeUser(user) {
    if (user) {
      setCurrentUser({ ...user })

      // check if provider is email and password login
      const isEmail = user.providerData.some(
        (provider) => provider.providerId === 'password'
      )
      setIsEmailUser(isEmail)

      setUserLoggedIn(true)
    } else {
      setCurrentUser(null)
      setUserLoggedIn(false)
    }

    setLoading(false)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser)
    return unsubscribe
  }, [])

  const value = {
    userLoggedIn,
    isEmailUser,
    currentUser,
    setCurrentUser
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
