import React, { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase/firebase'
import MainDataCard from '../../components/mainDataCard/MainDataCard'
import BrandEquity from '../../components/brandEquity/BrandEquity'
import Chart from '../../components/Chart/Chart'
import { Spin } from 'antd'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import { IBrandData } from './brand.interface'

const Brand = () => {
  const [brands, setBrands] = useState<IBrandData>()
  const { userData } = useUser()

  useEffect(() => {
    const fetchBrands = async () => {
      if (userData?.brand_id) {
        try {
          const brandRef = doc(db, 'brands', userData?.brand_id) // Get a reference to the document with the specific brandId
          const brandSnapshot = await getDoc(brandRef)
          if (brandSnapshot.exists()) {
            const brandData = { id: brandSnapshot.id, ...brandSnapshot.data() }
            //@ts-ignore
            setBrands(brandData) // You can set this to state if needed
          } else {
            console.log('No such brand exists')
            return null
          }
        } catch (error) {
          console.error('Error fetching brand:', error)
        }
      }
    }

    fetchBrands()
  }, [userData])

  return (
    <div className="max-w-6xl w-4/5 mx-auto mb-12">
      {brands ? (
        <>
          <MainDataCard cardData={brands} />
          <div className="mt-6">
            <div className="mb-2">Brand Equity</div>
            <BrandEquity
              stats={brands.stats.equity}
              isCurrencySigns
              titles={[
                'Positive Brand Equity',
                'Negative Brand Equity',
                'Net Brand Equity'
              ]}
            />
          </div>
          <div className="mt-6">
            <div className="mb-4">Brand Equity Over Time</div>
            <Chart dataChart={brands.stats.equity_chart} />
          </div>
          <div className="mt-6">
            <div className="mb-2">
              New Reviews
              {/*<span className="text-cons-gray-step-up-50 text-xs">*/}
              {/*  &nbsp; Current Week*/}
              {/*</span>*/}
            </div>
            <BrandEquity
              stats={brands.stats.latest_reviews}
              titles={[
                'Total Positive Reviews',
                'Total Neutral Reviews',
                'Total Negative Reviews'
              ]}
              isHideArrow
            />
          </div>
          {/* Hide aged reviews for now */}
          {/*<div className="mt-6">*/}
          {/*  <div className="mb-2">*/}
          {/*    Aged Reviews*/}
          {/*    <span className="text-cons-gray-step-up-50 text-xs">*/}
          {/*      &nbsp; Older than 12 months*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*<BrandEquity*/}
          {/*  stats={brands.stats.aged_reviews}*/}
          {/*  titles={[*/}
          {/*    'Total Positive Reviews',*/}
          {/*    'Total Neutral Reviews',*/}
          {/*    'Total Negative Reviews'*/}
          {/*  ]}*/}
          {/*/>*/}
          {/*</div>*/}
          <div className="mt-6">
            <div className="mb-2">Actions</div>
            <BrandEquity
              stats={brands.stats.actions}
              titles={[
                'Items with critical issues',
                'Items with poor reviews',
                'Items with too few reviews'
              ]}
              isLinkCard
              linkCard={['critical_issues', 'poor_reviews', 'too_few_reviews']}
            />
          </div>
        </>
      ) : (
        <Spin className="w-full flex justify-center pt-[50px]" />
      )}
    </div>
  )
}

export default Brand
