import React from 'react'
import { Spin } from 'antd'
import parse from 'html-react-parser'
import { IProductAlertsData } from '../../product.interface'
import '../analysis/Analysis.scss'

const AlertsTab = ({
  productAlertsData,
  isLoading
}: {
  productAlertsData?: IProductAlertsData
  isLoading: boolean
}) => {
  return (
    <div className="container mx-auto p-4">
      <h2 className="my-2 mt-8 font-medium">See Critical Issues</h2>
      <div className="bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
        {isLoading && (
          <div className="flex w-full justify-center text-center items-center h-40">
            <Spin size="large" />
          </div>
        )}

        {/* Check if the array is empty or contains only nulls */}
        {!productAlertsData?.critical_issues?.full_text && !isLoading ? (
          <div className="text-center text-gray-500 mt-8">
            Fortunately, we do not have critical issue alerts from reviews on
            this topic right now.
          </div>
        ) : (
          <div className="px-4 py-6">
            <div className="relative overflow-hidden">
              <div
                className={`text-box overflow-hidden transition-all duration-300 relative max-h-full`}
                style={{ height: 'auto' }}
              >
                {productAlertsData &&
                  parse(productAlertsData.critical_issues.full_text)}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AlertsTab
