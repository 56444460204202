import { TableProps } from 'antd'
import { simpleFormattedNumber } from '../../../../helpers/helperFunctions'
import { Link } from 'react-router-dom'
import React from 'react'

export function getBrandListConfig(): TableProps<any>['columns'] {
  return [
    {
      title: '№',
      width: 10,
      key: 'number',
      className: 'font-bold text-[#003C5B]',
      render: (value, record, index) => index + 1,
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Brand Name',
      dataIndex: 'name',
      width: 40,
      key: 'name',
      className: 'font-semibold text-[#003C5B]',
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Consumerly Index',
      width: '25px',
      align: 'center',
      key: 'consumerly-index',
      dataIndex: 'consumerly_index',
      className: 'font-bold text-[#422684]',
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Positive reviews',
      width: '25px',
      align: 'center',
      key: 'positive-reviews',
      dataIndex: 'positive_reviews',
      className: 'text-[#23A55A]',
      render: (val) => (val ? simpleFormattedNumber(val) : ''),
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Neutral reviews',
      width: '25px',
      align: 'center',
      key: 'neutral-reviews',
      dataIndex: 'neutral_reviews',
      className: 'text-[#D4BF00]',
      render: (val) => (val ? simpleFormattedNumber(val) : ''),
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Negative reviews',
      width: '25px',
      align: 'center',
      key: 'negative-reviews',
      dataIndex: 'negative_reviews',
      className: 'text-[#EE2752]',
      render: (val) => (val ? simpleFormattedNumber(val) : ''),
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: '',
      width: '20px',
      dataIndex: '',
      key: 'action',
      render: () => (
        <Link to={``} className="text-blue-500 underline">
          See Insights
        </Link>
      )
    }
  ]
}
