import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import BackButton from '../../components/backButton/BackButton'
import { Button, Spin } from 'antd'
import { ISentimentOverview } from '../productDetails/product.interface'
import { ReactComponent as AnalysisIcon } from '../../icons/analysis.svg'
import parse from 'html-react-parser'
import SentimentCard from '../../components/sentimentCard/SentimentCard'
import Chart from '../../components/Chart/Chart'

const SentimentOverview = () => {
  const { userData } = useUser()
  const { productId, customerSentimentId } = useParams()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false)
  const [isSummaryExpanded, setIsSummaryExpanded] = useState<boolean>(false)
  const [isPositiveSummaryExpanded, setIsPositiveSummaryExpanded] =
    useState<boolean>(false)
  const [isNegativeSummaryExpanded, setIsNegativeSummaryExpanded] =
    useState<boolean>(false)
  const [isRecommendationsExpanded, setIsRecommendationsExpanded] =
    useState<boolean>(false)
  const [summaryData, setSummaryData] = useState<any>(undefined)
  const [overviewData, setOverviewData] = useState<ISentimentOverview | null>(
    null
  )

  useEffect(() => {
    const db = getFirestore()
    const brandId: string = userData?.brand_id || ''

    if (customerSentimentId && productId) {
      setIsLoading(true)

      const docRef = doc(
        db,
        'brands',
        brandId,
        'products',
        productId,
        'customerSentiments',
        customerSentimentId
      )

      // Real-time listener for the document
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data() as ISentimentOverview
            setOverviewData(data)
            setSummaryData(data.summary_overview) // Automatically update summaryData when changed
          } else {
            console.error('No such document!')
          }
          setIsLoading(false)
        },
        (error) => {
          console.error('Error fetching document:', error)
          setIsLoading(false)
        }
      )

      // Cleanup the listener on component unmount
      return () => unsubscribe()
    }
  }, [productId, customerSentimentId, userData])

  const handleClick = () => {
    setIsGenerateLoading(true)
    setTimeout(() => {
      setIsGenerateLoading(false)
      setSummaryData(overviewData?.summary_overview)
    }, 3000)
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mt-8 mb-12 relative">
      {<BackButton />}
      {isLoading ? (
        <div className="grid min-h-[300px] place-items-center">
          <Spin size="large" />
        </div>
      ) : overviewData ? (
        <div>
          <div className="pt-2">
            {overviewData.title ? <>{overviewData.title}</> : 'Overview'}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
            <SentimentCard overviewData={overviewData} />
            <div className="relative p-6 border rounded-lg shadow-lg bg-white">
              <div className="flex justify-between text-sm">
                {overviewData.description && parse(overviewData.description)}
              </div>
            </div>
          </div>
          {overviewData.sentiment_history_chart && (
            <div className="mt-6">
              <div className="mb-4">Your Sentiment History</div>
              <Chart dataChart={overviewData.sentiment_history_chart} />
            </div>
          )}
          <div className="mt-8 bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
            {isGenerateLoading ? (
              <div className="grid min-h-[300px] place-items-center">
                <Spin size="large" />
              </div>
            ) : summaryData ? (
              <div className="space-y-4">
                {summaryData?.summary && (
                  <div className="rounded-lg p-4 mb-4">
                    <div className="flex justify-between">
                      <h2 className="font-bold text-xl mb-2 text-cons-blue">
                        Summary
                      </h2>
                    </div>

                    <div>
                      <div className="relative overflow-hidden">
                        <div
                          className={`text-box overflow-hidden transition-all duration-300 relative ${isSummaryExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                          style={
                            isSummaryExpanded
                              ? { height: 'auto' }
                              : { maxHeight: '160px' }
                          }
                        >
                          {parse(summaryData.summary)}
                        </div>
                      </div>
                      <button
                        onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}
                        className="text-cons-blue font-medium ml-8"
                      >
                        {isSummaryExpanded ? 'Read Less' : 'Read More'}
                      </button>
                    </div>
                  </div>
                )}
                <div className="flex flex-col sm:flex-row">
                  {summaryData?.positive_summary && (
                    <div className="rounded-lg p-4 mb-4 w-6/12 max-sm:w-full">
                      <div className="flex justify-between">
                        <h2 className="font-bold text-xl mb-2 text-cons-blue">
                          Positive Summary
                        </h2>
                      </div>

                      <div>
                        <div className="relative overflow-hidden">
                          <div
                            className={`text-box overflow-hidden transition-all duration-300 relative ${isPositiveSummaryExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                            style={
                              isPositiveSummaryExpanded
                                ? { height: 'auto' }
                                : { maxHeight: '160px' }
                            }
                          >
                            {parse(summaryData.positive_summary)}
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setIsPositiveSummaryExpanded(
                              !isPositiveSummaryExpanded
                            )
                          }
                          className="text-cons-blue font-medium ml-8"
                        >
                          {isPositiveSummaryExpanded
                            ? 'Read Less'
                            : 'Read More'}
                        </button>
                      </div>
                    </div>
                  )}
                  {summaryData?.negative_summary && (
                    <div className="rounded-lg p-4 mb-4 w-6/12 max-sm:w-full">
                      <div className="flex justify-between">
                        <h2 className="font-bold text-xl mb-2 text-cons-blue">
                          Negative Summary
                        </h2>
                      </div>

                      <div className="">
                        <div className="relative overflow-hidden">
                          <div
                            className={`text-box overflow-hidden transition-all duration-300 relative ${isNegativeSummaryExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                            style={
                              isNegativeSummaryExpanded
                                ? { height: 'auto' }
                                : { maxHeight: '160px' }
                            }
                          >
                            {parse(summaryData.negative_summary)}
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setIsNegativeSummaryExpanded(
                              !isNegativeSummaryExpanded
                            )
                          }
                          className="text-cons-blue font-medium ml-8"
                        >
                          {isNegativeSummaryExpanded
                            ? 'Read Less'
                            : 'Read More'}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {summaryData?.recommendations && (
                  <div className="rounded-lg p-4 mb-4">
                    <div className="flex justify-between">
                      <h2 className="font-bold text-xl mb-2 text-cons-blue">
                        Recommendations
                      </h2>
                    </div>

                    <div>
                      <div className="relative overflow-hidden">
                        <div
                          className={`text-box overflow-hidden transition-all duration-300 relative ${isRecommendationsExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                          style={
                            isRecommendationsExpanded
                              ? { height: 'auto' }
                              : { maxHeight: '160px' }
                          }
                        >
                          {parse(summaryData.recommendations)}
                        </div>
                      </div>
                      <button
                        onClick={() =>
                          setIsRecommendationsExpanded(
                            !isRecommendationsExpanded
                          )
                        }
                        className="text-cons-blue font-medium ml-8"
                      >
                        {isRecommendationsExpanded ? 'Read Less' : 'Read More'}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col sm:flex-row px-8">
                <div className="p-3 w-6/12 max-sm:w-full text-center">
                  <AnalysisIcon className="w-2/3 " />
                </div>
                <div className="ml-4 p-6 mb-4 w-6/12 max-sm:w-full flex items-center">
                  <div>
                    <h3 className="mb-4 font-semibold">
                      Would you like to start analyzing this product now?
                    </h3>
                    <div className="mb-4">
                      After activating this product, you will get unlimited
                      access to the Analysis & Recommendations for this month.
                    </div>
                    <Button
                      type="primary"
                      className="bg-cons-blue hover:bg-cons-blue-step-down h-10"
                      onClick={() => handleClick()}
                    >
                      Generate analysis
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="font-bold text-center">
          No cusotmer sentiments overivew for this product
        </div>
      )}
    </div>
  )
}

export default SentimentOverview
